import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Row, Col, Navbar, Nav, Image } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { File, Chat, Code, FileSearch, Database, Cube } from "@phosphor-icons/react";
import Logo from "./components/common/Logo.js";
// import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import { PopupButton } from '@typeform/embed-react';
import { ThemeContext } from "./Theme.js";

export default function Home() {

  const navigate = useNavigate();
  const location = useLocation();
  const pricingRef = useRef(null);
  let isPageWide = useMediaQuery('(min-width: 640px)');
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo(0,0);
  }, [0]);

  const scrollEffect = ( targetRef ) =>{
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  return (
    <Row style={{ justifyContent: 'center', minHeight: '95vh', width: '100%', marginLeft: 0, marginRight: 0 }}>
      <Col xs={12} sm={12} md={12} lg={11} xl={11} style={{ padding: 0 }}>
        <Navbar justify="true" fixed="top" variant={ theme === 'light-theme' ? "light" : "dark" } style={{ padding: '5px 20px', backgroundColor: `var(--bg-primary)` }}>
          <Logo width={120} />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {/* <Nav.Link>Platform</Nav.Link> */}
              {/* <Nav.Link>Pricing</Nav.Link> */}
              {/* <Nav.Link>Blog</Nav.Link> */}
              {/* <Nav.Link>About us</Nav.Link> */}
              {/* <Nav.Link onClick={() => scrollEffect(pricingRef)}>Pricing</Nav.Link> */}
              <Nav.Link><PopupButton id="ZysRhWGU" className="btn btn-link" style={{ marginTop: -2 }}>Book a demo</PopupButton></Nav.Link>
              {/* <Nav.Link href="https://forms.office.com/pages/responsepage.aspx?id=RtFHPUPp4EOUdieg1ZAbvHVwHx8SXvBCqYVC4LaL5kZUNEY4QlFZR1RDTVFaV0lNSEFXSk5SM1dSSi4u" target="_blank">Get free templates  <ArrowRight weight="bold" size={16} style={{ marginBottom: 3, marginLeft: 5 }} /></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* , backgroundImage: `url('/assets/frontpage/waves.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' */}

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 40, paddingBottom: 0 }}>
          <Col xs={11} sm={12} md={11} lg={10} xl={9} style={{ padding: isPageWide ? 20 : 0, maxWidth: 800, textAlign: 'center' }}>
            <h6 className='muted'>Revolutionize Your Workflow</h6>
            <h1 style={{ marginTop: isPageWide ? 20 : 10 }}>AI assistants for scientists & researchers</h1>
            {/* <h1 style={{ maxWidth: 600 }}>The AI assistant for scientific research</h1> */}
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: isPageWide ? 20 : 10 }}>
              <Col xs={11} sm={10} md={8} lg={8} xl={8} style={{ padding: 0 }}>
                <p className={ isPageWide ? 'large' : ''}>Terastack securely connects with your knowledge base of scientific publications, operating manuals, policy documents, legal contracts and more to automate search, retrieval, and summarization tasks - saving you hours every week.</p>
                <PopupButton id="ZysRhWGU" className="btn btn-secondary" style={{ marginTop: isPageWide ? 30 : 20 }}>
                  Book a demo
                </PopupButton>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={11} sm={11} md={6} lg={5} xl={5} style={{ padding: 20, maxWidth: 800 }}>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
              <Col xs={12} sm={11} md={11} lg={11} xl={11} style={{ padding: 0 }}>
                <p className='large' style={{ marginTop: isPageWide ? 40 : 10 }}><span className='italic'>Meet Terastack.</span> Save hours every week with instant literature search and retrieval. Leverage the power of your preferred LLM to chat with documents for the answers you need.</p>
              </Col>
            </Row>
          </Col> */}
        </Row>

        {/* Image of pdfs, doc, txt, json, xml, arxiv, pubmed on one side, Q&A on the other */}
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 20, textAlign: 'center', maxWidth: 1200 }}>
            <Image src={ isPageWide ? '/assets/frontpage/hero.png' : '/assets/frontpage/hero-mobile.png' } style={{ width: '100%' }} />
          </Col>
        </Row>

        {/* Works with all major LLMs */}
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 40 }}>
          <Col xs={9} sm={7} md={6} lg={5} xl={4} style={{ textAlign: 'center' }}>
            <p className='small muted' style={{ textAlign: 'center' }}>Works seamlessly with all major open and closed-source models</p> 
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, paddingTop: 20, paddingBottom: 80 }}>
          <Image src={'/assets/icons/openai.png'} style={{ width: 30, objectFit: 'cover', marginRight: 20 }} />
          <Image src={'/assets/icons/llama.png'} style={{ width: 30, objectFit: 'cover', marginRight: 20 }} />
          <Image src={'/assets/icons/gemini.png'} style={{ width: 30, objectFit: 'cover', marginRight: 20 }} />
          <Image src={'/assets/icons/anthropic.png'} style={{ width: 26, objectFit: 'cover', marginRight: 20 }} />
          <Image src={'/assets/icons/mistral.png'} style={{ width: 26, objectFit: 'cover', marginRight: 20 }} />
        </Row>

        {/* Logos */}

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, paddingTop: 80, marginTop: 0 }}>
          <Col xs={9} sm={7} md={6} lg={5} xl={4} style={{ textAlign: 'center' }}>
            <p style={{ textAlign: 'center' }}>Created by a team of former startup entrepreneurs and revenue managers with proven experience with growth marketing</p> 
          </Col>
        </Row> */}
        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, backgroundColor: '#111111', paddingTop: 40, paddingBottom: 80 }}>
          <Image src={'/assets/logos/yahoo.png'} style={{ width: 140, marginRight: 30 }} />
          <Image src={'/assets/logos/onecelsius.png'} style={{ width: 160, marginRight: 30 }} />
          <Image src={'/assets/logos/ubersense.png'} style={{ width: 160, marginRight: 30 }} />
          <Image src={'/assets/logos/florohealth.png'} style={{ width: 140, marginRight: 30 }} />
          <Image src={'/assets/logos/madeofbark.png'} style={{ width: 140, marginRight: 30 }} />
        </Row> */}

        {/* Key benefits */}
        {/* Daily chat, Upload docs, prompt library, query library, preferred LLMs, Secure and private */}

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={5} style={{ padding: isPageWide ? 20 : 10, textAlign: 'center', maxWidth: 900 }}>
            <h6 className='muted'>The Terastack Platform</h6>
            <h2 style={{ maxWidth: 600 }}>Generative AI for everyday research tasks</h2>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={11} sm={11} md={11} lg={11} xl={10} style={{ padding: 0 }}>
                <p className='large muted'>In the world of scientific research, efficiency and accuracy are paramount. From document chat to library querying, Terastack is a game-changer for research, saving valuable time while enhancing productivity.</p>
              </Col>
            </Row>
          </Col>
        </Row> */}

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
          <Col xs={11} sm={6} md={6} lg={6} xl={5} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <File size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Document chat</h4>
              <p className='muted' style={{ marginTop: 20 }}>Simply upload your PDF, DOCX, TXT, XML documents or import from PubMed or ArXiv - and then use Terastack to retrieve answers in a conversational manner without the need to look for a paper and then read through it entirely.</p> 
            </div>
          </Col>
          <Col xs={11} sm={6} md={6} lg={6} xl={5} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <Chat size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Daily chat</h4>
              <p className='muted' style={{ marginTop: 20 }}>Use Terastack's Daily chat tool to fetch answers to discovery questions from the open web. Or conveniently choose from a library of prompts for various daily tasks such as text translation, hypothesis generation, abstracts, grammar review, and more.</p> 
            </div>
          </Col>
          <Col xs={11} sm={6} md={6} lg={6} xl={5} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <Code size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Code chat</h4>  
              <p className='muted' style={{ marginTop: 20 }}>Use code-specific LLM models to quickly and efficiently debug your code in Python or other languages, ask the Terastack assistant to make code snippets more efficient, or have it assist you in solving your next coding challenge.</p> 
            </div>
          </Col>
          <Col xs={11} sm={6} md={6} lg={6} xl={5} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <FileSearch size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Library chat</h4>
              <p className='muted' style={{ marginTop: 20 }}>Terastack's library chat tool helps you extract answers from your entire library of documents using lightning fast <i>semantic search</i>. This saves you all the time wasted searching just file names using basic keyword search like other platforms do and spend more time on creative and innovative aspects of your work.</p>
            </div>
          </Col>
          <Col xs={11} sm={6} md={6} lg={6} xl={5} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, textAlign: 'left', borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <Database size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Prompt library</h4>
              <p className='muted' style={{ marginTop: 20 }}>Every Terastack plan includes a free and constantly growing library of thoughtfully crafted and tested prompts designed to help you complete essential tasks quickly and efficiently. Also build and add your own prompts for your specific use cases.</p> 
            </div>
          </Col>
          <Col xs={11} sm={6} md={6} lg={6} xl={5} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, textAlign: 'left', borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <Cube size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>LLM selection</h4>
              <p className='muted' style={{ textAlign: 'left', marginTop: 20 }}>Select the LLM models you wish to use from a selection of the most major models, both open source and closed source. Select models based on your priority of latency and cost.</p> 
            </div>
          </Col>
          <Col xs={11} sm={6} md={4} lg={4} xl={3} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, textAlign: 'left', borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', height: 300 }}>
              <Lock size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Security and privacy</h4>
              <p className='muted' style={{ textAlign: 'left', marginTop: 20 }}>With ever-increasing cost of capital and limited availability for funding opportunities, founders need to plan their growth even more carefully than they used to. Fuse can help you plan for sustainable growth.</p> 
            </div>
          </Col>
        </Row> */}

        {/* Pricing */}

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 80, paddingBottom: 40 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={5} style={{ padding: isPageWide ? 20 : 10, maxWidth: 800, textAlign: 'center' }}>
            <h6 className='muted'>Pricing</h6>
            <h2 style={{ maxWidth: 600 }}>For individuals & teams</h2>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={11} sm={10} md={8} lg={8} xl={8} style={{ padding: 0 }}>
                <p className='large'><span className='italic'>Meet Terastack.</span> Save hours every week with instant literature search and retrieval. Leverage the power of your preferred LLM to chat with documents for the answers you need.</p>
              </Col>
            </Row>
          </Col>
        </Row> */}

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <Col xs={11} sm={6} md={4} lg={4} xl={3} style={{ padding: 10 }}>
            <div style={{ padding: 30, paddingBottom: 40, textAlign: 'center', borderRadius: 10, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)' }}>
              <ChartLineUp size={28} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Document chat</h4>
              <p className='muted' style={{ textAlign: 'center', marginTop: 20 }}>With ever-increasing cost of capital and limited availability for funding opportunities, founders need to plan their growth even more carefully than they used to. Fuse can help you plan for sustainable growth.</p> 
            </div>
          </Col>
        </Row> */}

        {/* Who we are */}
        {/* <h6 style={{ textAlign: 'center', marginTop: 200 }}>Team</h6>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4} style={{ padding: 20, textAlign: 'center' }}>
            <Image src={'/assets/headshots/amit.png'} style={{ width: 160 }} roundedCircle />
            <h4 style={{ marginTop: 20 }}>Amit Jardosh</h4>
            <p style={{ textAlign: 'center', marginTop: 20 }}>Amit is a 3X tech founder of VC-backed and bootstrapped startups in sports, health, and climate - from inception to acquisition. Before jumping into the world of startups, he managed Pricing and Yield for Yahoo's Display Ads organization. He holds a Ph.D in Computer Science from UC Santa Barbara.</p>
          </Col>
        </Row> */}

        {/* <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 120, marginBottom: 120 }}>
          <Col xs={11} sm={11} md={10} lg={10} xl={8} style={{ padding: 80, backgroundColor: 'var(--bg-secondary)', border: '1px solid var(--separator)', borderRadius: 10 }}>
            <h3 style={{ textAlign: 'center' }}>Getting started</h3>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={12} sm={10} md={8} lg={6} xl={6} style={{ padding: 0, textAlign: 'center' }}>
                <p className='large'>All Terastack plans start with a free 14-day trial. Sign up for a demo below so we can get you set up.</p>
                <PopupButton id="ZysRhWGU" className="btn btn-primary" style={{ marginTop: 24 }}>
                  Book a demo
                </PopupButton>
              </Col>
            </Row>
          </Col>
        </Row> */}

      </Col>
      {/* TODO: Logo, policies, social media icons */}
      <Footer />
    </Row>
  )


}
